import React, {Component} from 'react';
import {UserService} from '../../services/user';
import {LoginService} from '../../services/login';
import './auth-with-token.css';

export default class ViewAuthWithToken extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    window.localStorage.setItem(window.location.host + "rdp-converter-current-language", this.props.match.params.language);
    UserService.setUser({
      "access_token": this.props.match.params.token,
      "type": "Bearer"
    });
    this.setSpecificProject(this.props.match.params.project);
  }

  setSpecificProject(project) {
    var userData = UserService.getUser();
    var type = userData.type;
    var accessToken = userData.access_token;
    // var path = this.props.match.params.path === "home" ? "" : "";
    var path = this.props.match.path.search("/embed/") > -1 ? "embed/" : "";

    LoginService.getUserInfo(type, accessToken).then(function(data) {
      LoginService.getProjectsList(type, accessToken).then(function(projects) {
        data.projects = projects;

        UserService.setUserInfo(data);
        UserService.setProjects(projects);
        for (var i = 0; i < projects.length; i++) {
          if (projects[i].uid === project) {
            UserService.setActiveProject(projects[i].uid, type, accessToken).then(function(projectInfo) {
              window.location = window.location.origin + window.location.pathname + "#/" + path;
            })
          }
        }
      }, function(error) {
        console.log(error);
      })
    }, function (error) {
      // self.setState({
      //   "loading": false
      // });
    })

    
  }

  render() {
    return <div className="rdp-composer-auth-with-token"></div>;
  }
}
