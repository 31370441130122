import $ from "jquery";
import jwtDecode from 'jwt-decode';
import RDP_CONFIG from '../config';

var userService = function() {
  this.getUserDecoded = function() {
    if (window.localStorage.getItem("rdp-converter-user-data") !== null) {
      return jwtDecode(JSON.parse(window.localStorage.getItem("rdp-converter-user-data")).access_token);
    } else {
      return null;
    }
  }

  this.getUser = function() {
    return JSON.parse(window.localStorage.getItem("rdp-converter-user-data"));
  }

  this.setUser = function(data) {
    window.localStorage.setItem("rdp-converter-user-data", JSON.stringify(data));
  }

  this.setUserInfo = function(data) {
    window.localStorage.setItem("rdp-converter-user-info", JSON.stringify(data));  
  }

  this.getUserInfo = function(data) {
    return JSON.parse(window.localStorage.getItem("rdp-converter-user-info"));
  }

  this.getProjects = function() {
    return JSON.parse(window.localStorage.getItem("rdp-converter-projects"));
  }

  this.setProjects = function(data) {
    window.localStorage.setItem("rdp-converter-projects", JSON.stringify(data));  
  }

  this.getActiveProject = function() {
    return JSON.parse(window.localStorage.getItem("rdp-converter-active-project"));
  }

  this.setActiveProjectLocal = function(data) {
    window.localStorage.setItem("rdp-converter-active-project", JSON.stringify(data));
  }

  this.loadUserInfo = async function(uid, type, accessToken) {
    var _this = this;
    var data = await new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/${RDP_CONFIG.apiVersion}/user/me`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": RDP_CONFIG.projectKey,
          "Api-Env": RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
      
        resolve(data)
      }, function(error) {
        reject(error)
      });
    });

    _this.setUserInfo(data);
  }

  this.loadProjects = async function(uid, type, accessToken) {
    var _this = this;
    var data = await new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/${RDP_CONFIG.apiVersion}/projects`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": RDP_CONFIG.projectKey,
          "Api-Env": RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
      
        resolve(data)
      }, function(error) {
        reject(error)
      });
    });

    _this.setProjects(data);
    _this.setActiveProject(data[0].uid, type, accessToken);
  }

  this.setActiveProject = function(uid, type, accessToken) {
    var _this = this;
    return new Promise(function(resolve, reject) {
      $.ajax({
        url: `https://${RDP_CONFIG.domain}/auth/${RDP_CONFIG.apiVersion}/project/uid/${uid}`,
        type: "GET",
        dataType: "json",
        headers: {
          "Authorization": type + " " + accessToken,
          "Project-Key": RDP_CONFIG.projectKey,
          "Api-Env": RDP_CONFIG.apiEnv
        }
      }).then(function(data) {
        _this.setActiveProjectLocal(data);
        resolve(data)
      }, function(error) {
        reject(error)
      });
    })
  }

  return this;
}

var UserService = new userService();

export {
  UserService
};
