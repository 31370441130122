import React, { Component } from 'react';
import './color-picker.css';
import { SketchPicker } from 'react-color';

export default class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active_color: props.color,
      opened: false,
      colors: ["#000000", "#444444", "#535353", "#7c7c7c", "#ffffff", "#b60000", "#ff0000", "#ff9900", "#ffff00", "#007c00", "#00ff00", "#00ffff", "#245df2"]
    };

  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.opened) {
      console.log('You clicked outside of me!');
      this.setState({ opened: false });
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  togglePicker = () => {
    this.setState({ opened: !this.state.opened })
  }

  onColorChange = (color) => {
    this.setState({
      active_color: color.hex
    }, () => {
      this.props.onChange(color);
    });
  }


  render() {
    const { active_color, colors, opened } = this.state;
    var labels = this.props.labels;
    return (
      <div ref={this.setWrapperRef} className={`rdp-composer-color-picker`}>
        <div style={{ backgroundColor: active_color }} className="color-picker-box-color">
          <span className="active-color">{active_color}</span>
          <button onClick={() => this.togglePicker()} >{opened ? labels.elements.common_settings.label_color_done : labels.elements.common_settings.label_color_change}</button>
          <SketchPicker width="354px" className={`composer-color-picker ${opened ? "color-picker-show" : "color-picker-hidden"} `} color={active_color} presetColors={colors} onChangeComplete={this.onColorChange} />
        </div>
      </div>
    );
  }
}
