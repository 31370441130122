import $ from 'jquery';
import jwtDecode from 'jwt-decode';
import React, {Component} from 'react';
import {UserService} from '../../services/user';
import {LoginService} from '../../services/login';
import uuidv1 from 'uuid/v1';
import JSZip from 'jszip';
import ReactJson from 'react-json-view';
import RDP_CONFIG from '../../config';
import api from '../../services/api';
import convertPresetProperty from "../../utils/convertPresetProperty";
import './ars-converter.css';
import {ConverterPresets} from '../../components';
import {decode} from "html-entities";

export default class ViewRiseToComposer extends Component {
  constructor(props) {
    super(props);
    this.riseInputFile = React.createRef();

    this.handleUploadBlobAssets = this.handleUploadBlobAssets.bind(this);
    this.onRiseInputFileChange = this.onRiseInputFileChange.bind(this);

    let userInfo = UserService.getUserInfo()
    let userName = "";

    if (userInfo) {
      userName = userInfo.detail === undefined ? userInfo.name : userInfo.detail.name;
    }

    this.state = {
      "username": userName,
      "projects": userInfo ? userInfo.projects : [],
      "active_project": UserService.getActiveProject(),
      "active_projects_dropdown": false,
      "active_user_dropdown": false,
      "upload_status": "not-selected",
      "extracted_files": [],
      "blob_assets": null,
      "rise_color": "#297C43",
      "rise_structure": null,
      "rise_lesson": null,
      "composer_structure": null,
      "composer_lesson": null,
      "current_structure": "rise",
      "uploading": false,
      "uploading_files_progress": 0,
      "uploading_files_counter": 0,
      "is_waiting_for_video_processing": false,
      "need_attention": false,
      /**
       * Permite ignorar a conversão dos tipos de objetos inseridos no array
       * @example
       * disabled_conversion_for_types: [
       *   {
       *     "type":    string,      // obrigatório
       *     "variant": string|null, // opcional
       *     "family":  string|null  // opcional
       *   },
       */
      "disabled_conversion_for_types": [
        {
          "type": "divider",
          "variant": "continue",
          "family": null
        },
      ]
    }
  }


  componentWillMount() {
    this.redirectToLogin();
    this.reloadValidUserToken();
  }

  reloadValidUserToken() {
    var userData = UserService.getUser();
    if (userData !== null) {
      var accessToken = userData.access_token;
      if (jwtDecode(accessToken).exp < Math.floor(Date.now() / 1000)) {
        this.logout();
      }
    }
  }

  logout() {
    LoginService.logout().then(function(data) {
      window.location = window.location.origin + window.location.pathname + "#/login";
    });
  }

  redirectToLogin() {
    if (window.localStorage.getItem("rdp-converter-user-data") === null) {
      window.location = window.location.origin + window.location.pathname + "#/login";
    }
  }

  async uploadFileAxios(file, config) {
    var connectorUid = JSON.parse(window.localStorage.getItem("rdp-converter-active-project")).connectors.find(item => item.type === "Learning_1").uid;

    const url = `https://${RDP_CONFIG.domain}/storage/v1.0/upload/connector/uid/${connectorUid}?manual_project_uid=${RDP_CONFIG.projectUid}&api_env=${RDP_CONFIG.apiEnv}&project_key=${RDP_CONFIG.projectKey}&access_token=${JSON.parse(window.localStorage.getItem("rdp-converter-user-data")).access_token}`;

    const data = new FormData();
    data.append('file', file);

    return api.post(url, data, config).then((response) => response).catch((error) => error.response);
  };

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  getCompositionFile(composition) {
    var myBlob = new Blob([JSON.stringify(composition)], { type: "application/json" });
    return this.blobToFile(myBlob, "composition.rdpcomposer");
  }

  async onSaveClick(composition) {
    var connectorUid = JSON.parse(window.localStorage.getItem("rdp-converter-active-project")).connectors[0].uid;
    var url = `https://${RDP_CONFIG.domain}/storage/v1.0/upload/connector/uid/${connectorUid}`;
    var fd = new FormData();
    var files = this.getCompositionFile(composition);
    var userData = JSON.parse(window.localStorage.getItem("rdp-converter-user-data"));
    var type = userData.type;
    var accessToken = userData.access_token;

    fd.append('file', files, "composition.rdpcomposer")

    $.ajax({
      url: url,
      type: "POST",
      data: fd,
      contentType: false,
      processData: false,
      headers: {
        "Authorization": type + " " + accessToken,
        "Project-Key" : RDP_CONFIG.projectKey,
        "Api-Env" : RDP_CONFIG.apiEnv
      },
      success: function (response) {
        console.info(response)
      },
      error: function (response) {
        console.error(response)
      }
    });
  }

  getFileType(asset) {
    var fileType = null;
    if (asset.data && asset.data[0] && asset.data[0].document && asset.data[0].document.content_type) {
      fileType = asset.data[0].document.content_type;
    }
    return fileType;
  }

  async handleUploadBlobAssets({blobAsset, index, allFilesLength, }) {
    var _this = this;
    let assetToReplace = null;

    const response = await _this.uploadFileAxios(blobAsset.file_blob, null, "ContentManager");

    let assetUid = response.data[0].uid;

    if (this.getFileType(response) === "video/mp4") {
      assetUid = await this.processVideo(response.data[0].document.file_uid, assetUid);

      assetToReplace = {
        "old": response.data[0].uid,
        "new": assetUid
      }
    }

    blobAsset.file_dam = `https://${RDP_CONFIG.domain}/storage/v1.0/content?uid=${assetUid}`
    let {
      uploading_files_counter
    } = this.state;

    _this.setState({
      uploading_files_counter: uploading_files_counter + 1,
      "uploading_files_progress": (((uploading_files_counter + 1) / allFilesLength) * 100).toFixed(0)
    })

    return {
      blobAsset,
      index,
      assetToReplace,
    }
  }

  startAssetsUploadRequests(blobAssets, allFilesLength) {
    return blobAssets.map((blobAsset, index) => this.handleUploadBlobAssets({
      blobAsset,
      index,
      allFilesLength
    }));
  }

  async onSaveCompositions() {
    this.setState({
      "uploading": true,
      "uploading_files_progress": 0,
      "uploading_files_counter": 0
    })

    var _this = this;
    var riseLessons = _this.onRiseGetLessons();
    var blobAssets = this.state.blob_assets;
    var allFilesLength = blobAssets.length + riseLessons.length;
    let assetsToReplace = [];

    await Promise.all(this.startAssetsUploadRequests(blobAssets, allFilesLength))
      .then((results) => {
        results.forEach((result) => {
          blobAssets[result.index] = result.blobAsset;
          if (result.assetToReplace) {
            assetsToReplace.push(result.assetToReplace);
          }
        });
      });

    var compositions = [];
    for (var i = 0; i < riseLessons.length; i++) {
      var assets = [];
      var composition = this.onComposerStructureCreate(riseLessons[i])
      var compositionString = JSON.stringify(composition);

      for (var j = 0; j < blobAssets.length; j++) {
        let fileNameEscape = escape(blobAssets[j].file_name);
        let fileNameEncoded = "content/assets/" + encodeURIComponent(blobAssets[j].file_name.replace("content/assets/", ""));
        if(compositionString.search(fileNameEscape) > -1) {
          assets.push(blobAssets[j].file_dam);
          compositionString = compositionString.replaceAll(fileNameEscape, blobAssets[j].file_dam)
        } else if(compositionString.search(blobAssets[j].file_name) > -1) {
          assets.push(blobAssets[j].file_dam);
          compositionString = compositionString.replaceAll(blobAssets[j].file_name, blobAssets[j].file_dam)
        } else if (compositionString.search(fileNameEncoded) > -1) {
          assets.push(blobAssets[j].file_dam);
          compositionString = compositionString.replaceAll(fileNameEncoded, blobAssets[j].file_dam)
        }
      }

      assetsToReplace.forEach(asset => {
        assets = assets.map(a => {
          if (a === asset.old) {
            return asset.new;
          }
          return a;
        })
        compositionString = compositionString.replaceAll(asset.old, asset.new);
      });

      composition = JSON.parse(compositionString);
      composition.assets = assets;
      compositions.push(composition);
    };

    let count = this.state.uploading_files_counter;

    for (var i = 0; i < compositions.length; i++) {
      await _this.onSaveClick(compositions[i]);
      count += 1;
      _this.setState({
        "uploading_files_progress": ((count / allFilesLength) * 100).toFixed(0),
        "uploading_files_counter": count
      })
    };
  }

  escapeString(str) {
    var temp;
    try {
      temp = decodeURIComponent(escape(str));
    } catch (e) {
      temp = str;
    }
    return temp;
  }

  convertRiseFile() {
    this.riseInputFile.current.click();
  }

  getPadding(padding, absolute = false) {
    return absolute ? parseInt(padding) : ((parseInt(padding) * 10) || 30);
  }

  async fetchStorageFile(uid) {
    const userData = JSON.parse(window.localStorage.getItem("rdp-converter-user-data"));

    return await $.ajax({
      url: `https://${RDP_CONFIG.domain}/storage/v1.0/search?filter=(file_uid eq '${uid}')`,
      type: "GET",
      contentType: false,
      processData: false,
      headers: {
        "Authorization": userData.type + " " + userData.access_token,
        "Project-Key" : RDP_CONFIG.projectKey,
        "Api-Env" : RDP_CONFIG.apiEnv
      },
    });
  }

  async processVideo(uid, assetUid) {
    let failedTries = 0;
    let newUid = assetUid;

    this.setState({
      is_waiting_for_video_processing: true
    });

    while (true) {
      await new Promise(resolve => setTimeout(resolve, 1000));

      try {
        let result = await this.fetchStorageFile(uid);
        if (result && result.results && result.results[0].document.content_type === "digitalpages/video" && (result.results[0].document.processing_status === "Processed" || result.results[0].document.processing_status === "NotApplicable")) {
          newUid = result.results[0].document.content_uid;
          break;
        }
      } catch (e) {
        console.error(e);
        failedTries += 1;
        if (failedTries < 3) {
          let result = await this.fetchStorageFile(uid);
          if (result && result.results && result.results[0].document.content_type === "digitalpages/video" && (result.results[0].document.processing_status === "Processed" || result.results[0].document.processing_status === "NotApplicable")) {
            newUid = result.results[0].document.content_uid;
            break;
          }
        } else {
          break;

        }
      }
    }

    this.setState({
      is_waiting_for_video_processing: false
    });

    return newUid;
  }

  decodeURItoString(str) {
    let resp;
    try {
      resp = decodeURI(str);
    } catch (e) {
      resp = unescape(str);
    }


    try {
      return decodeURIComponent(resp);
    } catch (e) {
      console.log(e);
    }
    
  }

  replaceSpecialChars(str, removeHtml = false) {
    if (typeof str === "string") {
      let element = document.createElement('div');
      element.innerHTML = str;
      // Retorna o conteúdo com os caracteres especiais convertidos.
      // https://www.html.am/reference/html-special-characters.cfm
      return decode(removeHtml ? this.decodeURItoString(escape(element.innerText)) : element.innerHTML, {level: 'html5'});
    }

    return str;
  }

  fixYoutubeEmbedUrl(url) {
    if (url.search("youtube.com/watch") > -1) {
      let videoId = url.split("v=")[1].split("&")[0];
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }
    return url;
  }

  onRiseConvertText(obj) {
    var structure;
    var variant = obj.variant;
    var settings = obj.settings;

    if (variant === "paragraph") {
      structure = {
        "id": uuidv1(),
        "type": "text-1",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("text-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "text": this.replaceSpecialChars(obj.items[0].paragraph || ""),
        "font_family": convertPresetProperty("text-1", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      };
    } else if (variant === "heading paragraph") {
      structure = {
        "id": uuidv1(),
        "type": "text-2",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("text-2", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "text": this.replaceSpecialChars((obj.items[0].heading || "") + (obj.items[0].paragraph || "")),
        "font_family": convertPresetProperty("text-2", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      };
    } else if (variant === "subheading paragraph") {
      structure = {
        "id": uuidv1(),
        "type": "text-3",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("text-3", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "text": this.replaceSpecialChars((obj.items[0].heading || "") + (obj.items[0].paragraph || "")),
        "font_family": convertPresetProperty("text-3", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      };
    } else if (variant === "heading") {
      structure = {
        "id": uuidv1(),
        "type": "text-4",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("text-4", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "text": this.replaceSpecialChars(obj.items[0].heading || ""),
        "font_family": convertPresetProperty("text-4", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      };
    } else if (variant === "subheading") {
      structure = {
        "id": uuidv1(),
        "type": "text-5",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("text-5", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "text": this.replaceSpecialChars(obj.items[0].heading || ""),
        "font_family": convertPresetProperty("text-5", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      };
    } else if (variant === "a") {
      var text = obj.items[0].paragraph || "";
      if (text !== "") {
        text = text.replaceAll("strong", "span");
        var frag = document.createRange().createContextualFragment(text);

        frag.childNodes[0].classList.add("ql-align-center");
        if (frag.childNodes[0].childNodes[0].getAttribute("style") === null) {
          frag.childNodes[0].childNodes[0].setAttribute("style", "font-size: 18px;");
        };
        text = frag.children[0].outerHTML;
      };
      structure = {
        "id": uuidv1(),
        "type": "statement-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("statement-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "border_color": convertPresetProperty("statement-1", "border_color"),
        "text": this.replaceSpecialChars(text),
        "font_family": convertPresetProperty("statement-1", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      };
    } else if (variant === "b") {
      var text = obj.items[0].paragraph || "";

      if (text.search("</span>") === -1) {
        text = text.replace('<p>', '<p class="ql-align-center"><span style="font-size: 26px; color: #313537;">');
        text = text.replace('</p>', '</span></p>');
      }

      structure = {
        "id": uuidv1(),
        "type": "statement-2",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("statement-2", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "border_color": convertPresetProperty("statement-2", "border_color") || "#d5d5d5",
        "text": this.replaceSpecialChars(text),
        "font_family": convertPresetProperty("statement-2", "font_family") || this.state.rise_structure.bodyTypeFace || "Helvetica Neue"
      };
    } else if (variant === "c") {
      var text = obj.items[0].paragraph || "";

      if (text.search("</span>") === -1) {
        text = text.replace('<p>', '<p><span style="font-size: 40px; color: #313537;">');
        text = text.replace('</p>', '</span></p>');
      }

      structure = {
        "id": uuidv1(),
        "type": "statement-3",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("statement-3", "background_color") || obj.settings.backgroundColor || "#ececec",
        "border_color": convertPresetProperty("statement-3", "border_color") || "#d5d5d5",
        "text": this.replaceSpecialChars(text),
        "font_family": convertPresetProperty("statement-3", "font_family") || this.state.rise_structure.bodyTypeFace || "Helvetica Neue"
      };
    } else if (variant === "d") {
      var text = obj.items[0].paragraph || "";
      if (text !== "") {
        text = text.replaceAll("strong", "span");
        var frag = document.createRange().createContextualFragment(text);

        if (frag.childNodes[0].childNodes[0].getAttribute("style") === null) {
          frag.childNodes[0].childNodes[0].setAttribute("style", "font-size: 30px; color: #313537;");
        };
        text = frag.children[0].outerHTML;
      };
      structure = {
        "id": uuidv1(),
        "type": "statement-4",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("statement-4", "background_color") || obj.settings.backgroundColor || "#f5f5f5",
        "border_color": convertPresetProperty("statement-4", "border_color") || "#d5d5d5",
        "text": this.replaceSpecialChars(text),
        "font_family": convertPresetProperty("statement-4", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      };
    } else if (variant === "note") {
      var text = obj.items[0].paragraph || "";
      if (text !== "") {
        text = text.replaceAll("strong", "span");
        var frag = document.createRange().createContextualFragment(text);

        frag.childNodes[0].classList.add("ql-align-center");
        if (frag.childNodes[0].childNodes[0].getAttribute("style") === null) {
          frag.childNodes[0].childNodes[0].setAttribute("style", "font-size: 14px; color: #313537;");
        };
        text = frag.children[0].outerHTML;
      };
      structure = {
        "id": uuidv1(),
        "type": "note-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("note-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "icon": "info",
        "primary_color": convertPresetProperty("note-1", "primary_color") || "#ff631e",
        "secondary_color": convertPresetProperty("note-1", "secondary_color") || "#ffefe9",
        "text": this.replaceSpecialChars(text),
        "font_family": convertPresetProperty("note-1", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      };
    } else if (variant === "two column") {
      structure = {
        "id": uuidv1(),
        "type": "text-two-column",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("text-two-column", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "items": this.replaceSpecialChars(obj.items.map(item => item.paragraph || "")),
        "font_family": convertPresetProperty("text-two-column", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      };
    }

    return structure;
  }

  onRiseConvertList(obj) {
    var structure;
    var typo;
    var settings = obj.settings;
    if (obj.variant === "numbered") typo = "list-1";
    if (obj.variant === "checkboxes") typo = "list-2";
    if (obj.variant === "bulleted") typo = "list-3";

    structure = {
      "id": uuidv1(),
      "type": typo,
      "content_title": null,
      "padding_top": this.getPadding(settings.paddingTop),
      "padding_bottom": this.getPadding(settings.paddingBottom),
      "dam_assets": [],
      "background_color": convertPresetProperty(typo, "background_color") || obj.settings.backgroundColor || "#FFFFFF",
      "primary_color": convertPresetProperty(typo, "primary_color") || "#297C43",
      "secondary_color": convertPresetProperty(typo, "secondary_color") || "#FFFFFF",
      "items": []
    };

    var items = obj.items;

    for (var i = 0; i < items.length; i++) {
      var text = items[i].paragraph || "";

      if (text.search("</span>") === -1) {
        text = text.replace('<p>', '<p><span style="font-size: 18px;">');
        text = text.replace('</p>', '</span></p>');
      };

      structure.items.push({
        "id": uuidv1(),
        "text": text,
        "image": null,
        "video": null
      });
    };

    return structure;
  }

  onRiseConvertImage(obj) {
    var structure;
    var settings = obj.settings;
    let getImage = (image) => {
      if ("crushedKey" in image) {
        return "content/assets/" + image.crushedKey;
      } else {
        return "content/assets/" + image.src;
      }
    }

    if (obj.variant === "hero") {
      structure = {
        "id": uuidv1(),
        "type": "image-1",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("image-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "image": getImage(obj.items[0].media.image),
        "caption": this.replaceSpecialChars(obj.items[0].caption),
      }
    } else if (obj.variant === "text aside") {
      structure = {
        "id": uuidv1(),
        "type": "image-and-text",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("image-and-text", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "image_width": "50%",
        "image_position": "left",
        "image_border_radius": 0,
        "image": getImage(obj.items[0].media.image),
        "text": this.replaceSpecialChars(obj.items[0].paragraph),
        "subtitle": obj.items[0].caption
      }
    } else if (obj.variant === "text overlay") {
      var text = obj.items[0].caption || "";

      if (text.search("</span>") === -1) {
        text = text.replace('<p>', '<p><span style="font-size: 30px; color: #FFFFFF;">');
        text = text.replace('</p>', '</span></p>');
      } else {
        text = text.replace('<p>', '<p><span style="color: #FFFFFF;">');
        text = text.replace('</p>', '</span></p>');
      }

      structure = {
        "id": uuidv1(),
        "type": "image-with-text",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("image-with-text", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "opacity": obj.settings.opacity * 100 + "%",
        "opacity_color": obj.settings.opacityColor,
        "divider_color": convertPresetProperty("image-with-text", "divider_color") || "#FFFFFF",
        "image": getImage(obj.items[0].media.image),
        "text": this.replaceSpecialChars(text)
      }
    } else if (obj.variant === "centered" && obj.family === "gallery") {
      structure = {
        "id": uuidv1(),
        "type": "gallery-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "primary_color": convertPresetProperty("gallery-1", "primary_color") || "#2d7b45",
        "background_color": convertPresetProperty("gallery-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "items": obj.items.map(item => ({
          "id": uuidv1(),
          "image": getImage(item.media.image),
          "caption": item.caption || "",
        })),
        "font_family": convertPresetProperty("gallery-1", "font_family") || this.state.rise_structure.bodyTypeFace || "Merriweather"
      }
    } else if ((obj.variant === "two column" || obj.variant === "three column" || obj.variant === "four column") && obj.family === "gallery") {
      structure = {
        "id": uuidv1(),
        "type": "images-gallery",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("images-gallery", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "images": obj.items.map(item => ({
          "id": uuidv1(),
          "src": getImage(item.media.image),
          "caption": this.replaceSpecialChars(item.caption || ""),
        })),
        "font_family": convertPresetProperty("images-gallery", "font_family") || this.state.rise_structure.bodyTypeFace || "Merriweather"
      }
    } else {
      structure = {
        "id": uuidv1(),
        "type": "image-2",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("image-2", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "image": getImage(obj.items[0].media.image),
      }
    }

    return structure;
  }

  onRiseConvertQuote(obj) {
    let structure;
    let settings = obj.settings;

    let getImage = (image) => {
      if (image) {
        if ("crushedKey" in image) {
          return "content/assets/" + image.crushedKey;
        } else {
          return "content/assets/" + image.src;
        }
      } else {
        return null;
      }
    }

    let getAvatarImage = (avatar) => {
      if (avatar) {
        if ("src" in avatar) {
          return "content/assets/" + avatar.src;
        } else {
          return "content/assets/" + avatar.key;
        }
      } else {
        return null;
      }
    }

    if (obj.variant === "a") {
      let text = obj.items[0].paragraph || "";
      let author = obj.items[0].name || "";

      if (text.search("</span>") === -1) {
        text = text.replace('<p>', '<p class="ql-align-center"><span style="font-size: 18px; color: #313537;">');
        text = text.replace('</p>', '</span></p>');
      };

      if (author.search("</span>") === -1) {
        author = author.replace('<p>', '<p class="ql-align-center"><span style="font-size: 14px; color: #313537;">');
        author = author.replace('</p>', '</span></p>');
      };

      structure = {
        "id": uuidv1(),
        "type": "quote-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "dam_assets": [],
        "primary_color": "#e9eaeb",
        "background_color": convertPresetProperty("quote-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "avatar": getAvatarImage(obj.items[0].avatar.media.image),
        "text": this.replaceSpecialChars(text),
        "author_name": this.replaceSpecialChars(author),
        "font_family": convertPresetProperty("quote-1", "font_family") || this.state.rise_structure.bodyTypeFace || "Merriweather"
      }
    } else if (obj.variant === "b") {
      let text = obj.items[0].paragraph || "";
      let author = obj.items[0].name || "";

      if (text.search("</span>") === -1) {
        text = text.replace('<p>', '<p class="ql-align-center"><span style="font-size: 30px; color: #5b5f60;">');
        text = text.replace('</p>', '</span></p>');
      };

      if (author.search("</span>") === -1) {
        author = author.replace('<p>', '<p class="ql-align-center"><span style="font-size: 14px; color: #297c43;">');
        author = author.replace('</p>', '</span></p>');
      };

      structure = {
        "id": uuidv1(),
        "type": "quote-2",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "primary_color": convertPresetProperty("quote-2", "primary_color") || "#e9eaeb",
        "background_color": convertPresetProperty("quote-2", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "avatar": getAvatarImage(obj.items[0].avatar.media.image),
        "text": this.replaceSpecialChars(text),
        "author_name": this.replaceSpecialChars(author),
        "font_family": convertPresetProperty("quote-2", "font_family") || this.state.rise_structure.bodyTypeFace || "Helvetica Neue"
      }
    } else if (obj.variant === "c") {
      let text = obj.items[0].paragraph || "";
      let author = obj.items[0].name || "";

      if (text.search("</span>") === -1) {
        text = text.replace('<p>', '<p><span style="font-size: 18px; color: #313537;">');
        text = text.replace('</p>', '</span></p>');
      };

      if (author.search("</span>") === -1) {
        author = author.replace('<p>', '<p><span style="font-size: 14px; color: #313537;">');
        author = author.replace('</p>', '</span></p>');
      };

      structure = {
        "id": uuidv1(),
        "type": "quote-3",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "primary_color":convertPresetProperty("quote-3", "primary_color") || "#f8f8f8",
        "background_color": convertPresetProperty("quote-3", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "avatar": getAvatarImage(obj.items[0].avatar.media.image),
        "text": this.replaceSpecialChars(text),
        "author_name": this.replaceSpecialChars(author),
        "font_family": convertPresetProperty("quote-3", "font_family") || this.state.rise_structure.bodyTypeFace || "Merriweather"
      }
    } else if (obj.variant === "d") {
      let text = obj.items[0].paragraph || "";
      let author = obj.items[0].name || "";

      if (text.search("</span>") === -1) {
        text = text.replace('<p>', '<p><span style="font-size: 18px; color: #313537;">');
        text = text.replace('</p>', '</span></p>');
      };

      if (author.search("</span>") === -1) {
        author = author.replace('<p>', '<p><span style="font-size: 14px; color: #313537;">');
        author = author.replace('</p>', '</span></p>');
      };

      structure = {
        "id": uuidv1(),
        "type": "quote-4",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "primary_color": convertPresetProperty("quote-4", "primary_color") || "#FFFFFF",
        "background_color": convertPresetProperty("quote-4", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "avatar": getAvatarImage(obj.items[0].avatar.media.image),
        "text": this.replaceSpecialChars(text),
        "author_name": this.replaceSpecialChars(author),
        "font_family": convertPresetProperty("quote-4", "font_family") || this.state.rise_structure.bodyTypeFace || "Merriweather"
      }
    } else if (obj.variant === "background" && obj.family === "quote") {
      let text = obj.items[0].paragraph || "";
      let author = obj.items[0].name || "";

      if (text.search("</span>") === -1) {
        text = text.replace('<p>', '<p><span style="font-size: 26px; color: #FFFFFF;">');
        text = text.replace('</p>', '</span></p>');
      };

      if (author.search("</span>") === -1) {
        author = author.replace('<p>', '<p><span style="font-size: 14px; color: #FFFFFF;">');
        author = author.replace('</p>', '</span></p>');
      };

      structure = {
        "id": uuidv1(),
        "type": "quote-5",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "primary_color": convertPresetProperty("quote-5", "background_color") || "#FFFFFF",
        "background_image": "content/assets/" + (obj.items[0].background.media.image.crushedKey || obj.items[0].background.media.image.src),
        "avatar": getAvatarImage(obj.items[0].avatar.media.image),
        "text": this.replaceSpecialChars(text),
        "author_name": this.replaceSpecialChars(author),
        "font_family": convertPresetProperty("quote-5", "font_family") || this.state.rise_structure.bodyTypeFace || "Merriweather"
      }
    } else if (obj.variant === "carousel") {
      let getItem = (item) => {
        var text = item.paragraph || "";
        var author = item.name || "";

        if (text.search("</span>") === -1) {
          text = text.replace('<p>', '<p><span style="font-size: 26px; color: #000;">');
          text = text.replace('</p>', '</span></p>');
        };

        if (item.name) {
          author = author.replace(/<\/?[^>]+(>|$)/g, "");
          author = `<p>${author}</p>`
        };
        return {
          "id": uuidv1(),
          "avatar": "content/assets/" + item.avatar.media.image.src,
          "background": getImage(obj.items[0].avatar.media.image),
          "text": this.replaceSpecialChars(text),
          "author_name": this.replaceSpecialChars(author),
        }
      }

      structure = {
        "id": uuidv1(),
        "type": "quote-6",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "primary_color": convertPresetProperty("quote-6", "primary_color") || "#2d7b45",
        "background_color": convertPresetProperty("quote-6", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "items": obj.items.map(getItem),
        "font_family": convertPresetProperty("quote-6", "font_family") || this.state.rise_structure.bodyTypeFace || "Merriweather"
      }
    }

    return structure;
  }

  onRiseConvertInteractive(obj) {
    var structure;
    var settings = obj.settings;

    if (obj.variant === "accordion") {
      structure = {
        "id": uuidv1(),
        "type": "accordion",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("accordion", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "behavior": "keep-open",
        "border_color": convertPresetProperty("accordion", "border_color") || "#ff9900",
        "items": []
      };

      var items = obj.items;

      for (var i = 0; i < items.length; i++) {
        structure.items.push({
          "expanded": false,
          "id": uuidv1(),
          "image": null,
          "text": this.replaceSpecialChars(items[i].description),
          "title": this.replaceSpecialChars(items[i].title, true),
          "video": null
        });
      }
    } else if (obj.variant === "process") {
      const itemIntro = obj.items.find(item => item.type === "intro");
      const itemSummary = obj.items.find(item => item.type === "summary");
      const getImage = (item) => {
        if ("media" in item && "image" in item.media) {
          return "content/assets/" + item.media.image.src;
        }
        return null;
      }
      const getAudio = (item) => {
        if ("media" in item && "audio" in item.media) {
          return "content/assets/" + item.media.audio.key;
        }
        return null;
      }

      let items = obj.items.filter(item => item.type === "step").map((item) => ({
        "id": uuidv1(),
        "type": item.type || "step",
        "title": this.replaceSpecialChars(item.title || ""),
        "isHidden": item.isHidden || false,
        "description": this.replaceSpecialChars(item.description || ""),
        "image": getImage(item) || null,
        "audio": getAudio(item) || null,
      }));

      if (itemIntro) {
        items.unshift({
          "id": uuidv1(),
          "type": "intro",
          "title": this.replaceSpecialChars(itemIntro.title || ""),
          "isHidden": itemIntro.isHidden || false,
          "description": this.replaceSpecialChars(itemIntro.description || ""),
          "image": getImage(itemIntro) || null,
          "audio": getAudio(itemIntro) || null,
        });
      }

      if (itemSummary) {
        items.push({
          "id": uuidv1(),
          "type": "summary",
          "title": this.replaceSpecialChars(itemSummary.title || ""),
          "isHidden": itemSummary.isHidden || false,
          "description": this.replaceSpecialChars(itemSummary.description || ""),
          "image": getImage(itemSummary) || null,
          "audio": getAudio(itemSummary) || null,
        });
      }

      structure = {
        "id": uuidv1(),
        "type": "steps-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "card_height": 360,
        "card_width": 360,
        "background_color": convertPresetProperty("steps-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("steps-1", "primary_color") || "#00643e",
        "items": items,
      }
    } else if (obj.variant === "timeline") {
      const getImage = (item) => {
        if ("media" in item && "image" in item.media) {
          return "content/assets/" + item.media.image.crushedKey;
        }
        return null;
      }
      const getAudio = (item) => {
        if ("media" in item && "audio" in item.media) {
          return "content/assets/" + item.media.audio.key;
        }
        return null;
      }
      const getEmbed = (item) => {
        if ("media" in item && "embed" in item.media) {
          return {
            src: item.media.embed.src,
            title: item.media.embed.title,
            favicon: item.media.embed.favicon,
            provider: item.media.embed.provider,
            embedType: item.media.embed.embedType,
            description: item.media.embed.description,
            originalUrl: this.fixYoutubeEmbedUrl(item.media.embed.originalUrl),
            providerUrl: item.media.embed.providerUrl,
          };
        }
        return null;
      }

      structure = {
        "id": uuidv1(),
        "type": "timeline-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("timeline-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("timeline-1", "primary_color") || "#00643e",
        "items": obj.items.map((item) => ({
          "id": uuidv1(),
          "title": this.replaceSpecialChars(item.title || ""),
          "date": this.replaceSpecialChars(item.date || ""),
          "description": this.replaceSpecialChars(item.description || ""),
          "image": getImage(item) || null,
          "audio": getAudio(item) || null,
          "embed": getEmbed(item) || null,
        })),
      }
    } else if (obj.variant === "button") {
      structure = {
        "id": uuidv1(),
        "type": "button-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("button-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("button-1", "primary_color") || "#00643e",
        "items": obj.items.map((item) => ({
          "id": uuidv1(),
          "type": item.type || "",
          "label": this.replaceSpecialChars(item.label || "", true),
          "description": this.replaceSpecialChars(item.description || ""),
          "destination": item.destination || "",
        })),
      }
    } else if (obj.variant === "button stack") {
      structure = {
        "id": uuidv1(),
        "type": "button-2",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("button-2", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("button-2", "primary_color") || "#00643e",
        "items": obj.items.map((item) => ({
          "id": uuidv1(),
          "type": item.type || "",
          "label": this.replaceSpecialChars(item.label || "", true),
          "description": this.replaceSpecialChars(item.description || ""),
          "destination": item.destination || "",
        })),
      }
    } else if (obj.variant === "stack" && obj.family === "flashcard") {
      const getSideAtributes = (item) => {
        let src = null;

        if ("media" in item && "image" in item.media) {
          let file = item.media.image.src;

          if ("crushedKey" in item.media.image && !("src" in item.media.image)) {
            file = item.media.image.crushedKey;
          }

          src = "content/assets/" + file
        }

        switch (item.type) {
          case "fullimage":
            return {
              "centered_image": null,
              "fullscreen_image": src,
              "text": null,
            }
          case "image":
            return {
              "centered_image": src,
              "fullscreen_image": null,
              "text": null,
            }
          default:
            return {
              "centered_image": null,
              "fullscreen_image": null,
              "text": this.replaceSpecialChars(item.description || ""),
            }
        }
      }
      structure = {
        "id": uuidv1(),
        "type": "flashcards-2",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "card_height": obj.card_height || 360,
        "card_width": obj.card_width || 360,
        "background_color": convertPresetProperty("flashcards-2", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "border_color": convertPresetProperty("flashcards-2", "border_color"),
        "items": obj.items.map((item) => ({
          "id": uuidv1(),
          "front_card": {
            ...getSideAtributes(item.front),
          },
          "back_card": {
            ...getSideAtributes(item.back),
          }
        })),
      }
    } else if (obj.variant === "tabs") {
      let getImage = (item) => {
        if ("media" in item && "image" in item.media) {
          return "content/assets/" + item.media.image.src;
        }
        return null;
      }

      structure = {
        "id": uuidv1(),
        "type": "tabs",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("tabs", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "active_color": convertPresetProperty("tabs", "active_color"),
        "items": obj.items.map((item) => ({
          "id": uuidv1(),
          "title": this.replaceSpecialChars(item.title || "", true),
          "text": this.replaceSpecialChars(item.description || ""),
          "image": getImage(item) || null,
          "video": null
        }))
      }
    } else if (obj.variant === "flashcard" && obj.family === "flashcard") {
      const getSideAtributes = (item) => {
        let src = null;

        if ("media" in item && "image" in item.media) {
          let file = item.media.image.src;

          if ("crushedKey" in item.media.image && !("src" in item.media.image)) {
            file = item.media.image.crushedKey;
          }

          src = "content/assets/" + file;
        }

        switch (item.type) {
          case "fullimage":
            return {
              "centered_image": null,
              "fullscreen_image": src,
              "text": null,
            }
          case "image":
            return {
              "centered_image": src,
              "fullscreen_image": null,
              "text": null,
            }
          default:
            return {
              "centered_image": null,
              "fullscreen_image": null,
              "text": this.replaceSpecialChars(item.description || ""),
            }
        }
      }

      structure = {
        "id": uuidv1(),
        "type": "flashcards-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "card_height": obj.card_height || 240,
        "card_width": obj.card_width || 240,
        "background_color": convertPresetProperty("flashcards-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "border_color": convertPresetProperty("flashcards-1", "border_color"),
        "items": obj.items.map((item) => ({
          "id": uuidv1(),
          "front_card": {
            ...getSideAtributes(item.front),
          },
          "back_card": {
            ...getSideAtributes(item.back),
          }
        })),
      }
    } else if (obj.variant === "labeledgraphic" && obj.family === "interactive-fullscreen") {
      const getImage = (item) => {
        if ("media" in item && "image" in item.media) {
          return "content/assets/" + item.media.image.src;
        }
        return null;
      }
      const getAudio = (item) => {
        if ("media" in item && "audio" in item.media) {
          return "content/assets/" + item.media.audio.key;
        }
        return null;
      }

      const getWidth = (settings) => {
        switch (settings.mediaWidth) {
          case "2":
            return "full";
          case "0":
            return "small";
          case "1":
            return "medium";
          default:
            return null;
        }
      }

      const getBackgroundImage = (image) => {
        if (image) {
          if ("crushedKey" in image) {
            return "content/assets/" + image.crushedKey;
          } else {
            return "content/assets/" + image.src;
          }
        } else {
          return null;
        }
      }

      structure = {
        "id": uuidv1(),
        "type": "hotspots-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "width": getWidth(settings),
        "background_color": convertPresetProperty("hotspots-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "spot_color": convertPresetProperty("hotspots-1", "spot_color") || settings.markerColor || "#00643e",
        "image": getBackgroundImage(obj.media.image),
        "image_alt": obj.media.image.alt || "",
        "spots": obj.items.map((item) => ({
          "id": uuidv1(),
          "x": item.x || "0",
          "y": item.y || "0",
          "icon": item.icon || "",
          "title": this.replaceSpecialChars(item.title || "",  true),
          "description": this.replaceSpecialChars(item.description || ""),
          "hasMedia": item.hasMedia || false,
          "isActive": item.isActive || false,
          "image": getImage(item) || null,
          "audio": getAudio(item) || null,
        })),
      }
    } else if (obj.variant === "sorting" && obj.family === "interactive-fullscreen") {
      let piles = obj.piles.map((item) => ({
        "id": uuidv1(),
        "title": item.title || "",
        "originalId": item.id
      }));

      structure = {
        "id": uuidv1(),
        "type": "sorted-activity-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("sorted-activity-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("sorted-activity-1", "primary_color") || "#297C43",
        "pile_color": convertPresetProperty("sorted-activity-1", "pile_color") || "#e1e1e1",
        "pile_text_color": convertPresetProperty("sorted-activity-1", "pile_text_color") || "#000",
        "item_color": convertPresetProperty("sorted-activity-1", "item_color") || "#FFFFFF",
        "item_text_color": convertPresetProperty("sorted-activity-1", "item_text_color") || "#000",
        "items": obj.items.map((item) => {
          const pile = piles.find(pile => pile.originalId === item.pileId);
          return {
            "id": uuidv1(),
            "title": this.replaceSpecialChars(item.title || ""),
            "pileId": pile.id,
          };
        }),
        "piles": piles.map((item) => {
          delete item.originalId;
          return item;
        }),
      }
    } else if (obj.variant === "storyline" && obj.family === "360") {
      // TODO: implement storyline
      this.setState({
        "need_attention": true,
      })
    }

    return structure;
  }

  onRiseConvertMultimedia(obj) {
    var structure;
    var settings = obj.settings;

    if (obj.variant === "audio") {
      let getAssetPath = (asset) => {
        if ("crushedKey" in asset) {
          return "content/assets/" + asset.crushedKey;
        } else {
          return "content/assets/" + asset.key;
        }
      }
      structure = {
        "id": uuidv1(),
        "type": "audio-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("audio-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "audio": getAssetPath(obj.items[0].media.audio)
      }
    } else if (obj.variant === "video") {
      let getAssetPath = (asset) => {
        if ("crushedKey" in asset) {
          return "content/assets/" + asset.crushedKey;
        } else {
          return "content/assets/" + asset.key;
        }
      }
      structure = {
        "id": uuidv1(),
        "type": "video-1",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("video-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "video": getAssetPath(obj.items[0].media.video)
      }
    } else if (obj.variant === "embed") {
      let getEmbedUrl = (item) => {
        if ("media" in item && "embed" in item.media) {
          if (item.media.embed.originalUrl.search("<iframe") !== -1) {
            let element = document.createElement("div");
            element.innerHTML = item.media.embed.originalUrl;
            return this.fixYoutubeEmbedUrl(element.firstChild.src);
          } else {
            return this.fixYoutubeEmbedUrl(item.media.embed.originalUrl);
          }
        }
        return null;
      }
      structure = {
        "id": uuidv1(),
        "type": "iframe",
        "content_title": null,
        "dam_assets": [],
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("iframe", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "height": 560,
        "width": 800,
        "url": getEmbedUrl(obj.items[0])
      }
    } else if (obj.variant === "attachment") {
      structure = {
        "id": uuidv1(),
        "type": "attachment-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("attachment-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("attachment-1", "primary_color") || "#00643e",
        "items": obj.items.map((item) => ({
          "id": uuidv1(),
          "type": item.media.attachment.type || "attachment",
          "size": item.media.attachment.size || 0,
          "filename": item.media.attachment.filename || "file",
          "mime_type": item.media.attachment.mimeType || "",
          "original_url": "content/assets/" + item.media.attachment.key,
        })),
        "font_family": convertPresetProperty("attachment-1", "font_family") || this.state.rise_structure.bodyTypeFace || "Merriweather"
      }
    } else if (obj.variant === "code") {
      structure = {
        "id": uuidv1(),
        "type": "snippet-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("snippet-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "snippet_background_color": convertPresetProperty("snippet-1", "snippet_background_color") || obj.settings.snippetBackgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("snippet-1", "primary_color") || "#00643e",
        "items": obj.items.map((item) => ({
          "id": uuidv1(),
          "code": item.code || "",
          "caption": item.caption || "",
        })),
      }
    }

    return structure;
  }

  onRiseConvertKnowledgeCheck(obj) {
    let structure;
    let settings = obj.settings;

    let getQuestionImage = (image) => {
      if (image) {
        if ("crushedKey" in image) {
          return "content/assets/" + image.crushedKey;
        } else {
          return "content/assets/" + image.key;
        }
      }
      return null;
    }

    if (obj.variant === "multiple choice" && (obj.items.find((item) => item.type.toLowerCase() === "matching") === undefined)) {
      structure = {
        "id": uuidv1(),
        "type": "quiz-1",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("quiz-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("quiz-1", "primary_color") || "#00643e",
        "remake": "disable",
        "max_attempts": null,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "feedback": {
          "type": "default",
        },
        "questions": obj.items.map((item) => ({
          "id": uuidv1(),
          "question": this.replaceSpecialChars(item.title || ""),
          "image": item.media ? getQuestionImage(item.media.image) : null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": this.replaceSpecialChars(item.feedback || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": this.replaceSpecialChars(item.feedbackCorrect || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": this.replaceSpecialChars(item.feedbackIncorrect || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": item.answers.map((answer) => ({
            "id": uuidv1(),
            "correct": answer.correct,
            "text": this.replaceSpecialChars(answer.title || "", true),
          })),
        }))
      }
    } else if (obj.variant === "multiple response") {
      structure = {
        "id": uuidv1(),
        "type": "quiz-2",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("quiz-2", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("quiz-2", "primary_color") || "#00643e",
        "remake": "disable",
        "max_attempts": null,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "feedback": {
          "type": "default",
        },
        "questions": obj.items.map((item) => ({
          "id": uuidv1(),
          "question": this.replaceSpecialChars(item.title || ""),
          "image": item.media ? getQuestionImage(item.media.image) : null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": this.replaceSpecialChars(item.feedback || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": this.replaceSpecialChars(item.feedbackCorrect || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": this.replaceSpecialChars(item.feedbackIncorrect || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": item.answers.map((answer) => ({
            "id": uuidv1(),
            "correct": answer.correct,
            "text": this.replaceSpecialChars(answer.title || "", true),
          })),
        }))
      }
    } else if (obj.variant === "fillin") {
      structure = {
        "id": uuidv1(),
        "type": "quiz-3",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("quiz-3", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("quiz-3", "primary_color") || "#00643e",
        "remake": "disable",
        "max_attempts": null,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "feedback": {
          "type": "default",
        },
        "questions": obj.items.map((item) => ({
          "id": uuidv1(),
          "question": this.replaceSpecialChars(item.title || ""),
          "image": item.media ? getQuestionImage(item.media.image) : null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": this.replaceSpecialChars(item.feedback || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": this.replaceSpecialChars(item.feedbackCorrect || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": this.replaceSpecialChars(item.feedbackIncorrect || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": item.answers.map((answer) => ({
            "id": uuidv1(),
            "text": this.replaceSpecialChars(answer.title || "", true),
          })),
        }))
      }
    } else if (obj.variant === "matching" || (obj.items.find((item) => item.type.toLowerCase() === "matching") !== undefined)) {
      structure = {
        "id": uuidv1(),
        "type": "quiz-4",
        "content_title": null,
        "padding_top": this.getPadding(settings.paddingTop),
        "padding_bottom": this.getPadding(settings.paddingBottom),
        "background_color": convertPresetProperty("quiz-4", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "primary_color": convertPresetProperty("quiz-4", "primary_color") || "#00643e",
        "remake": "disable",
        "max_attempts": null,
        "utilization": {
          "enabled": false,
          "percentage": null
        },
        "feedback": {
          "type": "default",
        },
        "questions": obj.items.map((item) => ({
          "id": uuidv1(),
          "question": this.replaceSpecialChars(item.title || ""),
          "image": item.media ? getQuestionImage(item.media.image) : null,
          "video": null,
          "answered": false,
          "feedback_default": {
            "text": this.replaceSpecialChars(item.feedback || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_correct": {
            "text": this.replaceSpecialChars(item.feedbackCorrect || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "feedback_incorrect": {
            "text": this.replaceSpecialChars(item.feedbackIncorrect || null, true),
            "image": null,
            "video": null,
            "media_max_width": null
          },
          "no_correct_answer": false,
          "no_feedback": false,
          "choices": item.answers.map((answer) => ({
            "id": uuidv1(),
            "correct": answer.correct,
            "title": this.replaceSpecialChars(answer.title || "", true),
            "matchTitle": this.replaceSpecialChars(answer.matchTitle || "", true),
          })),
        }))
      }
    }

    return structure;
  }

  onRiseConvertDivider(obj) {
    let structure;
    let settings = obj.settings;

    if (obj.variant === "continue") {
      let title = this.replaceSpecialChars(obj.items[0].title || "");
      structure = {
        "id": uuidv1(),
        "type": "virtual-index-1",
        "content_title": null,
        "title": title.toLowerCase() === "continue" ? "continuar" : title,
      }
    } else if (obj.variant === "divider") {
      structure = {
        "id": uuidv1(),
        "type": "divider-1",
        "content_title": null,
        "height": 1,
        "padding_top": this.getPadding(settings.paddingTop, true),
        "padding_bottom": this.getPadding(settings.paddingBottom, true),
        "background_color": convertPresetProperty("divider-1", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "divider_color": convertPresetProperty("divider-1", "divider_color") || "#e6e6e6",
      }
    } else if (obj.variant === "numbered divider") {
      structure = {
        "id": uuidv1(),
        "type": "divider-numbered",
        "content_title": null,
        "height": 1,
        "padding_top": this.getPadding(settings.paddingTop, true),
        "padding_bottom": this.getPadding(settings.paddingBottom, true),
        "background_color": convertPresetProperty("divider-numbered", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "divider_primary_color": convertPresetProperty("divider-numbered", "divider_primary_color") || "#297C43",
        "divider_secondary_color": convertPresetProperty("divider-numbered", "divider_secondary_color") || "#FFFFFF",
        "font_family": convertPresetProperty("divider-numbered", "font_family") || this.state.rise_structure.bodyTypeFace || "Grueber"
      }
    } else if (obj.variant === "spacing divider") {
      structure = {
        "id": uuidv1(),
        "type": "divider-4",
        "content_title": null,
        "height": 10,
        "padding_top": this.getPadding(settings.paddingTop, true),
        "padding_bottom": this.getPadding(settings.paddingBottom, true),
        "background_color": convertPresetProperty("divider-4", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
        "divider_color": convertPresetProperty("divider-4", "background_color") || obj.settings.backgroundColor || "#FFFFFF",
      }
    }

    return structure;
  }

  onRiseLessonClick(lesson) {
    this.setState({
      "rise_lesson": lesson,
      "composer_lesson": this.onComposerStructureCreate(lesson)
    })
  }

  onRiseLessonClose() {
    this.setState({
      "rise_lesson": null,
      "composer_lesson": null
    })
  }

  onComposerStructureCreate(lesson) {
    let structure = this.onComposerGetCommonStructure();
    structure.metadata.title = this.escapeString(lesson.rdp_name);
    let composerItems = [];
    let riseItems = lesson.items;
    const convertMapFn = {
      "text": this.onRiseConvertText,
      "interactive": this.onRiseConvertInteractive,
      "multimedia": this.onRiseConvertMultimedia,
      "image": this.onRiseConvertImage,
      "gallery": this.onRiseConvertImage,
      "list": this.onRiseConvertList,
      "quote": this.onRiseConvertQuote,
      "knowledgeCheck": this.onRiseConvertKnowledgeCheck,
      "divider": this.onRiseConvertDivider,
    }

    riseItems.forEach(obj => {
      const isConversionForTypeDisabled = undefined !== this.state.disabled_conversion_for_types.find(item => item.type === obj.type && (item.variant || obj.variant) === obj.variant && (item.family || obj.family) === obj.family);
      if (false === isConversionForTypeDisabled) {
        let item = convertMapFn[obj.type].bind(this)(obj);
        if (item) composerItems.push(item);
      }
    });

    structure.structure = composerItems;

    if (this.needLessonFinishButtonEnabled(structure.structure)) {
      structure = this.enableLessonFinishButton(structure);
      if (this.needDividerAtBeginning(structure.structure)) {
        structure.structure = this.addDividerAtBeginning(structure.structure);
      }
      if (this.hasDividerAtEnd(structure.structure)) {
        structure.structure = this.removeDividerFromEnd(structure.structure);
      }
    }

    return structure;
  }

  needLessonFinishButtonEnabled(structure) {
    return structure.some((item) => item.type === "virtual-index-1");
  }

  needDividerAtBeginning(structure) {
    if (structure.length > 0) {
      return structure[0].type !== "virtual-index-1";
    }
    return false;
  }

  addDividerAtBeginning(structure) {
    structure.unshift({
      "id": uuidv1(),
      "type": "virtual-index-1",
      "content_title": null,
      "title": "CONTINUE"
    });
    return structure;
  }

  hasDividerAtEnd(structure) {
    if (structure.length > 0) {
      return structure[structure.length - 1].type === "virtual-index-1";
    }
    return false;
  }

  removeDividerFromEnd(structure) {
    if (structure.length > 0 && structure[structure.length - 1].type === "virtual-index-1") {
      structure.pop();
    }
    return structure;
  }

  enableLessonFinishButton(composition) {
    if (composition && "interface" in composition) {
      return {
        ...composition,
        interface: {
          ...composition.interface,
          "finish_btn": "enabled"
        }
      }
    }
    return composition;
  }

  onRiseGetLessons() {
    var structure = this.state.rise_structure;
    var title = this.escapeString(structure.course.title);
    var lessons = structure.course.lessons;
    for (var i = 0; i < lessons.length; i++) {
      lessons[i].rdp_name = `${title} - ${this.escapeString(lessons[i].title)}`;
    };

    return lessons;
  }

  onComposerGetCommonStructure() {
    return {
      "assets": [],
      "interface": {
        "content_language": "pt_br",
        "font_family": "Lato"
      },
      "metadata": {
        "description": null,
        "tags": [],
        "thumb": null,
        "title": null
      },
      "structure": [],
      "version": "1.1"
    };
  }

  getMimeType(fileName) {
    var mimetype = "application"
    var extension = fileName.split(".")[1];

    if (extension === "jpg"
      || extension === "jpeg"
      || extension === "png"
      || extension === "gif"
      || extension === "bmp"
      || extension === "webp"
      || extension === "ico") {
        mimetype = "image";
    } else if (extension === "mp4"
      || extension === "mov"
      || extension === "wmv"
      || extension === "avi"
      || extension === "webm"
      || extension === "m4v"
      || extension === "ogv") {
        mimetype = "video";
    } else if (extension === "aac"
      || extension === "mp3"
      || extension === "weba"
      || extension === "m4v"
      || extension === "oga"
      || extension === "wav") {
        mimetype = "audio";
    } else if (extension === "zip"
      || extension === "rar"
      || extension === "ppt"
      || extension === "pdf"
      || extension === "epub"
      || extension === "xls"
      || extension === "xlsx") {
      mimetype = "application";
    };

    if (extension === "jpg") extension = "jpeg";
    if (extension === "svg") extension = "svg+xml";
    if (extension === "ico") extension = "x-icon";
    if (extension === "avi") extension = "x-msvideo";
    if (extension === "ogv") extension = "ogg";
    if (extension === "oga") extension = "ogg";
    if (extension === "wav") extension = "x-wav";
    if (extension === "weba") extension = "webm";
    if (extension === "rar") extension = "x-rar-compressed";
    if (extension === "ppt") extension = "vnd.ms-powerpoint";
    if (extension === "epub") extension = "epub+zip";
    if (extension === "xls") extension = "application/vnd.ms-excel";
    if (extension === "xlsx") extension = "application/vnd.ms-excel";

    return `${mimetype}/${extension}`;
  }

  isAssetFile(file) {
    const isDir = file.dir;
    const isInAssetFolder = file.name.search("content/assets") !== -1;
    const hasName = file.name.length > "content/assets".length;

    return !isDir && isInAssetFolder && hasName;
  }

  async onRiseInputFileChange(e) {
    try {
      e.persist();
      const files = e.target.files;

      const zip = await JSZip.loadAsync(files[0], {"createFolders": true});

      let zipFiles = Object.values(zip.files);
      let assets = zipFiles.filter(this.isAssetFile.bind(this));
      let tempBlobFiles = [];

      for (const asset of assets) {
        let file = zip.file(asset.name);
        if (file !== null) {
          let fileBuffer = await file.async("ArrayBuffer");
          let blob = new Blob([new Uint8Array(fileBuffer).buffer], {type: this.getMimeType(asset.name)});
          tempBlobFiles.push({
            "file_name": asset.name,
            "file_blob": blob,
            "file_dam": null
          });
        } else {
          console.error(`File ${asset.name} not found`);
        }
      }

      // PACOTES ANTIGOS DO RISE
      // const htmlLocalFile = String(await zip.file("content/index.html").async("text"));
      // const riseColor = htmlLocalFile.split("background-color: ")[1].split(" !")[0];
      // const riseStructure = localFile.split("window.courseData =")[1]
      //   .split("window.partnerContent = [];")[0]
      //   .split(";")[0]
      //   .replaceAll(" ", "")
      //   .replaceAll('"', '');
      
      const localFile = String(await zip.file("content/locales/und.js").async("text"));
      const riseStructure = localFile.split('__resolveJsonp("course:und","')[1].split('")')[0];
      const jsonStructure = JSON.parse(atob(riseStructure));

      this.setState({
        "upload_status": "done",
        "rise_color": jsonStructure.course.color,
        "rise_structure": jsonStructure,
        "extracted_files": Object.values(zip.files),
        "blob_assets": tempBlobFiles,
      });
    } catch (error) {
      console.error(error);
    }
  }

  onCurrentStructureChange(structureType) {
    this.setState({
      "current_structure": structureType
    })
  }

  closeUploading() {
    this.setState({
      "upload_status": "not-selected",
      "extracted_files": [],
      "blob_assets": null,
      "rise_color": "#297C43",
      "rise_structure": null,
      "rise_lesson": null,
      "composer_structure": null,
      "composer_lesson": null,
      "current_structure": "rise",
      "uploading": false,
      "uploading_files_progress": 0,
      "uploading_files_counter": 0,
      "is_waiting_for_video_processing": false,
      "need_attention": false,
    })
  }

  toggleUserDropdown() {
    this.setState({
      "active_user_dropdown": !this.state.active_user_dropdown
    });
  }

  toggleProjectsDropdown() {
    this.setState({
      "active_projects_dropdown": !this.state.active_projects_dropdown
    });
  }

  changeProject(project) {
    this.setState({
      "displayed_preloader": true
    });
    const userData = UserService.getUser();
    const type = userData.type;
    const accessToken = userData.access_token;

    UserService.setActiveProject(project.uid, type, accessToken).then((projectInfo) => {
      window.location.reload();
    })
  }

  render() {
    var _this = this;
    var activeProjectName = this.state.active_project !== null ? this.state.active_project.name : "";
    return (<div className="rdp-composer-ars-converter">
      <div className="rdp-composer-ars-converter-header">
        <span className="rdp-composer-ars-converter-header-title">Conversor</span>
        <input className="rdp-composer-ars-converter-header-upload-input" ref={this.riseInputFile} type="file" onChange={this.onRiseInputFileChange}/>

        {(this.state.upload_status === "done" && !this.state.uploading) && (
          <>
            <button className="rdp-composer-ars-converter-header-upload-btn" onClick={() => this.convertRiseFile()}>Alterar arquivo</button>
          </>
        )}

        <div className="rdp-composer-header-active-project" onClick={() => this.toggleProjectsDropdown()}>
          <span className="rdp-composer-header-active-project-label">PROJETO</span>
          <span className={`rdp-composer-header-active-project-name ${this.state.active_projects_dropdown ? "open" : ""}`}>
            {activeProjectName}
          </span>

          {this.state.active_projects_dropdown && (
            <div className="rdp-composer-header-projects-dropdown-container">
              <div className="rdp-composer-header-projects-dropdown-overlay" onClick={() => this.toggleProjectsDropdown()}></div>
              <ul className="rdp-composer-header-projects-dropdown-popup">
                {this.state.projects.map((project, index) => (
                  <li key={index} className={`rdp-composer-header-projects-dropdown-popup-name ${this.state.active_project.uid === project.uid ? "active" : ""}`} onClick={() => this.changeProject(project)}>{project.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div
          className="rdp-composer-header-active-user"
          onClick={() => this.toggleUserDropdown()}
        >
          <span className="rdp-composer-header-active-user-avatar"></span>
          <span className="rdp-composer-header-active-user-hello">OLÁ,</span>
          <span className={`rdp-composer-header-active-user-name ${this.state.active_user_dropdown ? "open" : ""}`}>
            {this.state.username}
          </span>

          {this.state.active_user_dropdown === true && (
            <div className="rdp-composer-header-user-dropdown-container">
              <div className="rdp-composer-header-user-dropdown-overlay" onClick={() => this.toggleUserDropdown()}></div>
              <div className="rdp-composer-header-user-dropdown-popup">
                <span className="rdp-composer-header-user-dropdown-signout" onClick={() => this.logout()}>SAIR</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="rdp-composer-ars-converter-sidebar">
        <ConverterPresets/>
      </div>

      {this.state.upload_status === "done" ? (
        <div className="rdp-composer-ars-converter-compositions">
          <div className="rdp-composer-ars-converter-compositions-header">Unidades</div>
          <ul className="rdp-composer-ars-converter-compositions-items">
            {this.onRiseGetLessons().map(function (lesson, index) {
              return <li className={`rdp-composer-ars-converter-compositions-item ${_this.state.rise_lesson && _this.state.rise_lesson.id === lesson.id ? "active": ""}`} key={index}>
                <span className="rdp-composer-ars-converter-compositions-item-name">{lesson.rdp_name}</span>
                <span className="rdp-composer-ars-converter-compositions-item-icon-code" onClick={() => _this.onRiseLessonClick(lesson)}></span>
              </li>
            })}
          </ul>
          <div className="rdp-composer-ars-converter-compositions-actions">
            <button className="rdp-composer-ars-converter-compositions-actions-btn" onClick={() => this.onSaveCompositions()}>Iniciar conversão</button>
          </div>
          {this.state.uploading &&
            <div className="rdp-composer-ars-converter-compositions-uploading">
              <div className="rdp-composer-ars-converter-compositions-uploading-modal">
                <span className="rdp-composer-ars-converter-compositions-uploading-label">{parseInt(this.state.uploading_files_progress) === 100 ? "Conversão concluída!" : "Realizando conversão..."}</span>
                <div className="rdp-composer-ars-converter-compositions-uploading-percentage-box">
                  <div className="rdp-composer-ars-converter-compositions-uploading-percentage-bar" style={{width: `${this.state.uploading_files_progress}%`}}></div>
                  <span className="rdp-composer-ars-converter-compositions-uploading-percentage-label">{this.state.uploading_files_progress}%</span>
                </div>

                {
                  this.state.is_waiting_for_video_processing && (
                    <>
                      <span className="rdp-composer-ars-converter-compositions-video-processing-label">Aguardando processamento de vídeo...</span>
                      <div className="rdp-composer-ars-converter-waiting-bar">
                        <div className="rdp-composer-ars-converter-waiting-bar-bar">
                        </div>
                      </div>
                    </>
                  )
                }

                {parseInt(this.state.uploading_files_progress) === 100 &&
                  <>
                    <div className="rdp-composer-ars-converter-compositions-uploading-message">
                      A conversão dos conteúdos foi executada com sucesso! Confira todos os conteúdos migrados, na página inicial do Composer. Você poderá editá-los normalmente.
                    </div>

                    {this.state.need_attention &&
                      <div className="rdp-composer-ars-converter-compositions-uploading-message-alert">
                        <svg width="24" height="24" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                        </svg>
                        Há a necessidade de revisão em alguns objetos, edite a composição para realizar as alterações necessárias.
                      </div>
                    }
                    <button className="rdp-composer-ars-converter-compositions-uploading-close-btn" onClick={() => this.closeUploading()}>Fechar janela</button>
                  </>
                }
              </div>
            </div>
          }
        </div>
      ) : (
        <div className="rdp-composer-ars-converter-compositions">
          <div className="rdp-composer-ars-converter-dropzone-container">
            <div
              className="rdp-composer-ars-converter-dropzone"
              onClick={() => this.convertRiseFile()}
            >
              <button
                className="rdp-composer-ars-converter-header-upload-btn"
                onClick={() => this.convertRiseFile()}
              >
                Escolher arquivo
              </button>
            </div>
          </div>
        </div>
      )}

      {this.state.rise_lesson &&
        <div className="rdp-composer-ars-converter-structure">
          <div className="rdp-composer-ars-converter-structure-header">
            <span onClick={() => this.onCurrentStructureChange("rise")} className={`rdp-composer-ars-converter-structure-header-tab ${this.state.current_structure === "rise" ? "active" : ""}`}>Estrutura de Origem</span>
            <span onClick={() => this.onCurrentStructureChange("composer")} className={`rdp-composer-ars-converter-structure-header-tab ${this.state.current_structure === "composer" ? "active" : ""}`}>Estrutura Composer</span>
            <span onClick={() => this.onRiseLessonClose()} className="rdp-composer-ars-converter-structure-header-close"></span>
          </div>
          <div className="rdp-composer-ars-converter-structure-json">
            <ReactJson
              src={this.state.current_structure === "rise" ? this.state.rise_lesson : this.state.composer_lesson}
              displayObjectSize={false}
              displayDataTypes={false}
              enableClipboard={false}
            />
          </div>
        </div>
      }
    </div>);
  }
}
