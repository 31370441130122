import React, { Component } from 'react';
import {Route} from 'react-router-dom';
import {UserService} from './services/user';
import './reset.css';
import './App.css';
import "animate.css/animate.min.css";
import RDP_CONFIG from "./config";

import {
  ViewAuthWithToken,
  ViewLogin,
  ViewRiseToComposer
} from './views';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = 
    {
      "loading" : true
    }

    this.loadConfig = this.loadConfig.bind(this);
    this.loadCustomOptions = this.loadCustomOptions.bind(this);
    this.loadCustomOptions();
  }

  componentWillMount() {
    const _this = this;
    const pathComponents = window.location.pathname.split("/");
    RDP_CONFIG.language = "pt_br"
    if (window.localStorage.getItem(window.location.host + "rdp-converter-current-language") !== null) {
      RDP_CONFIG.language = window.localStorage.getItem(window.location.host + "rdp-converter-current-language");
    };

    for(var i=0; i<pathComponents.length;i++)
    {
      var component = pathComponents[i];
      if (component != "config") continue;

      RDP_CONFIG.configBase64 = pathComponents[i+1];
      const data = JSON.parse(atob(RDP_CONFIG.configBase64));

      this.loadConfig(data);
      break;
    }

    if (RDP_CONFIG.configBase64) return;

    fetch("./api_context.json").then(async response =>{
      const config = await response.json();
      await this.loadConfig(config);
    });
  }

  async loadConfig(data)
  {
    RDP_CONFIG.config = data;
    RDP_CONFIG.projectKey = (data.project_key || data.pk);

    if (data.custom_login_background != undefined || data.clb != undefined){
      RDP_CONFIG.customLoginBackground = data.custom_login_background || data.clb;
    }

    if (data.api_env != undefined || data.ae != undefined){
      RDP_CONFIG.apiEnv = (data.api_env || data.ae);
    }

    if (data.project_uid != undefined || data.pu != undefined) {
      RDP_CONFIG.projectUid = data.project_uid || data.pu;
    }

    if (data.domain != undefined) {
      RDP_CONFIG.domain = data.domain;
    }

    if (RDP_CONFIG.directToken)
    {
      UserService.setUser({access_token:RDP_CONFIG.directToken, type:"Bearer"});
    }
    
    if ( RDP_CONFIG.projectUid && RDP_CONFIG.projectUid.length == 36)
    {
      await UserService.setActiveProject(RDP_CONFIG.projectUid, "Bearer", RDP_CONFIG.directToken);
    }
    else if (RDP_CONFIG.directToken && (!RDP_CONFIG.projectUid || RDP_CONFIG.projectUid.length < 36))
    {
      await UserService.loadProjects(RDP_CONFIG.projectUid, "Bearer", RDP_CONFIG.directToken);
      await UserService.loadUserInfo(RDP_CONFIG.projectUid, "Bearer", RDP_CONFIG.directToken);
    }

    this.setState({"loading":false});
  }

  loadCustomOptions()
  {
    if (window.location.search.length <= 1 || RDP_CONFIG.directToken) return;

    var search = window.location.search.substr(1, window.location.search.length);
    var params = search.split('&');
    var data = {};

    for (const index in params) {
      var param = params[index];
      var values = param.split("=");
      if (values.length <2) continue;

      data[values[0]] = values[1];
    }

    if (data["custom_options"])
    {
      var options = JSON.parse(atob(data["custom_options"]));
      if (options.hasOwnProperty("access_token")) RDP_CONFIG.directToken = options["access_token"];
      if (options.hasOwnProperty("mode")) RDP_CONFIG.customExibitionMode = options["mode"];
    }
    
    if (data["access_token"])
    {
      RDP_CONFIG.directToken = data["access_token"];
    }

    if (data["language"])
    {
      //refactor: centralizar esse tipo de informação
      window.localStorage.setItem(window.location.host + "rdp-dam-current-language", data["language"]);
    }
  }

  render() {
    const {loading} = this.state;

    if (loading) return<div></div>;

    return (
      <div id="root" className="rdp-composer-app">
        <Route exact={true} path="/auth-with-token/:language/:path/:project/:token" component={ViewAuthWithToken}/>
        <Route exact={true} path="/embed/auth-with-token/:language/:path/:project/:token" component={ViewAuthWithToken}/>
        <Route exact={true} path="/login" component={ViewLogin}/>
        <Route exact={true} path="/" component={ViewRiseToComposer}/>
      </div>
    );
  }
}

export default App;
