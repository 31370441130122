import axios from 'axios';
import RDP_CONFIG from '../config';

const api = axios.create({
  baseURL: `https://api.digitalpages.com.br/cms/${RDP_CONFIG.apiVersion}/`
});

var userData = JSON.parse(window.localStorage.getItem("rdp-converter-user-data"));

if (userData) {
  var type = userData.type;
  var accessToken = userData.access_token;

// Request Interceptor
  api.interceptors.request.use(
    (config) => {
      config.headers = {
        'Content-Type': 'application/json',
        "Authorization": type + " " + accessToken,
        "Project-Key" : RDP_CONFIG.projectKey,
        "Api-Env" : RDP_CONFIG.apiEnv
      };
      return config;
    },
    (error) => Promise.reject(error),
  );
}

// Response Interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log('UNAUTHORIZED USER');
      localStorage.clear();
    }
    return Promise.reject(error);
  },
);

export default api;
